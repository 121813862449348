import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, showNotification } from "react-admin";
import axios from "axios";

const entrypoint = process.env.REACT_APP_ENTRYPOINT;

class BulkActivateButton extends Component {
  handleClick = () => {
    const { resource, selectedIds, showNotification } = this.props;
    const token = localStorage.getItem("token");

    const prettyResources = {
      users: "Users",
      user_companies: "Relations",
    };

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/resource/activate`,
      data: {
        ids: selectedIds.map((item) => item.substr(item.lastIndexOf("/") + 1)),
        resourceName: resource,
      },
    })
      .then((response) => {
        showNotification(`${prettyResources[resource]} validated`);
        // refreshView();
        window.location.reload(); // doing this to uncheck the checked items
      })
      .catch(function (error) {
        showNotification(
          `Error: ${prettyResources[resource]} not validated`,
          "warning"
        );
      });
  };

  render() {
    return <Button label="Validate" onClick={this.handleClick} />;
  }
}

BulkActivateButton.propTypes = {
  showNotification: PropTypes.func,
};

export default connect(undefined, {
  showNotification,
})(BulkActivateButton);
