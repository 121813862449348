import React from "react";
import {
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  CreateGuesser,
} from "@api-platform/admin";
import {
  SelectInput,
  SelectField,
  TextField,
  Pagination,
  Filter,
  TextInput,
  SelectArrayInput,
} from "react-admin";

const StatusSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      { id: "wait_email_validation", name: "Waiting for email validation" },
    ]}
    {...props}
  />
);

const RoleSelectInput = (props) => (
  <SelectArrayInput
    choices={[
      { id: "ROLE_USER", name: "User" },
      { id: "ROLE_ADMIN", name: "Admin" },
      { id: "ROLE_SUPER_ADMIN", name: "Super Admin" },
    ]}
    {...props}
  />
);

const StatusSelectField = (props) => (
  <SelectField
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      { id: "wait_email_validation", name: "Waiting for email validation" },
    ]}
    {...props}
  />
);

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="Last Name" source="lastName" />
    <TextInput label="Username" source="username" />
    <StatusSelectInput source={"status"} />
  </Filter>
);

export const UserAdminList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<UserFilter />}
    filterDefaultValues={{ type: "admin" }}
    exporter={false}
  >
    <TextField source={"firstName"} />
    <TextField source={"lastName"} />
    <TextField source={"email"} />
    <StatusSelectField source="status" />
  </ListGuesser>
);

export const UserAdminShow = (props) => (
  <ShowGuesser {...props}>
    <TextField source="firstName" addLabel={true} />
    <TextField source="lastName" addLabel={true} />
    <TextField source="email" addLabel={true} />
    <TextField source="username" addLabel={true} />
    <TextField source="phone" addLabel={true} />
    <StatusSelectField source="status" addLabel={true} />
    <TextField source="roles" addLabel={true} />
  </ShowGuesser>
);

export const UserAdminEdit = (props) => (
  <EditGuesser {...props}>
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="email" />
    <TextInput source="username" />
    <TextInput source="phone" />
    <StatusSelectInput source="status" />
    <RoleSelectInput source="roles" />
  </EditGuesser>
);

export const UserAdminCreate = (props) => (
  <CreateGuesser {...props}>
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="email" />
    <TextInput source="username" />
    <TextInput source="phone" />
    <StatusSelectInput source="status" />
    <RoleSelectInput source="roles" />
    <TextInput source="plainPassword" />
  </CreateGuesser>
);
