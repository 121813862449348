import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Title } from "react-admin";
import axios from "axios";

const START_YEAR = 2000;
const CURRENT_YEAR = new Date().getFullYear();

const DEFAULT_COUNTRY = "BE";

const entrypoint = process.env.REACT_APP_ENTRYPOINT;

const styles = {
  paper: {
    padding: "30px",
    textAlign: "center",
    marginRight: "24px",
    cursor: "pointer",
  },
  number: {
    fontSize: "100px",
    lineHeight: "1em",
    fontWeight: "bold",
  },
  subject: {
    fontSize: "20px",
    lineHeight: "30px",
    textTransform: "uppercase",
  },
  waiting: {
    fontSize: "12px",
    textTransform: "uppercase",
  },
  progress: {
    width: "50px",
    height: "50px",
    margin: "25px",
  },
  title: {
    marginBottom: "0.6em",
  },
  container: {
    display: "flex",
    marginBottom: "2em",
  },
  paperTable: {
    width: "100%",
  },
  select: {
    marginBottom: "0.6em",
  },
};

function createData(
  year,
  country,
  companies,
  beneficiaries,
  declarations,
  amount_eur,
  amount_gbp,
  amount_chf,
  amount_usd
) {
  return {
    year,
    country,
    companies,
    beneficiaries,
    declarations,
    amount_eur,
    amount_gbp,
    amount_chf,
    amount_usd,
  };
}

class Dashboard extends Component {
  state = {
    usersLoading: true,
    affiliationsLoading: true,
    users: 0,
    affiliations: 0,
    year: CURRENT_YEAR - 1,
    country: DEFAULT_COUNTRY,
    loadingYear: true,
    loadingCountry: true,
    rowsYear: [],
    rowsCountry: [],
    loadingCountries: true,
  };

  countries = {};

  componentDidMount() {
    const token = localStorage.getItem("token");

    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/countries?status=active&pagination=false`,
    })
      .then((response) => {
        const countries = {};
        response.data["hydra:member"].forEach((item) => {
          countries[item.isoCode] = item.name;
        });
        this.countries = countries;
        this.setState({
          loadingCountries: false,
        });

        this.loadStatisticYear(this.state.year);
        this.loadStatisticCountry(this.state.country);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/users?status=wait_admin_validation`,
    })
      .then((response) => {
        this.setState({
          usersLoading: false,
          users: response.data["hydra:totalItems"],
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/user_companies?status=wait_admin_validation`,
    })
      .then((response) => {
        this.setState({
          affiliationsLoading: false,
          affiliations: response.data["hydra:totalItems"],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChangeYear = (e) => {
    const year = e.target.value;
    this.loadStatisticYear(year);
    this.setState({
      year,
    });
  };

  handleChangeCountry = (e) => {
    const country = e.target.value;
    this.loadStatisticCountry(country);
    this.setState({
      country,
    });
  };

  loadStatisticYear = (year) => {
    const token = localStorage.getItem("token");

    this.setState({ loadingYear: true, rowsYear: [] });

    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/statistic/year/${year}`,
    })
      .then((response) => {
        const rowsYear = [];
        Object.values(response.data).forEach((item) => {
          rowsYear.push(
            createData(
              year,
              this.countries[item.country],
              item.companyNumber,
              item.beneficiaryNumber,
              item.declarationNumber,
              item.amountByCurrency["EUR"] || 0,
              item.amountByCurrency["GBP"] || 0,
              item.amountByCurrency["CHF"] || 0,
              item.amountByCurrency["USD"] || 0
            )
          );
        });
        this.setState({
          loadingYear: false,
          rowsYear,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  loadStatisticCountry = (country) => {
    const token = localStorage.getItem("token");

    this.setState({ loadingCountry: true, rowsCountry: [] });

    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/statistic/country/${country}`,
    })
      .then((response) => {
        const rowsCountry = [];
        Object.values(response.data).forEach((item) => {
          rowsCountry.push(
            createData(
              item.year,
              this.countries[country],
              item.companyNumber,
              item.beneficiaryNumber,
              item.declarationNumber,
              item.amountByCurrency["EUR"] || 0,
              item.amountByCurrency["GBP"] || 0,
              item.amountByCurrency["CHF"] || 0,
              item.amountByCurrency["USD"] || 0
            )
          );
        });
        this.setState({
          loadingCountry: false,
          rowsCountry,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const years = [];
    for (let i = START_YEAR; i <= CURRENT_YEAR; i++) {
      years.push(i);
    }
    return (
      <Card>
        <Title title="Transparent MedTech Admin" />
        <CardContent>
          <Typography
            variant="display1"
            component="h2"
            gutterBottom
            style={styles.title}
          >
            Waiting for validation
          </Typography>
          <div style={styles.container}>
            <Paper
              style={styles.paper}
              onClick={() => {
                window.location =
                  "/#/users?filter=%7B%22status%22%3A%22wait_admin_validation%22%7D";
              }}
            >
              {this.state.usersLoading ? (
                <CircularProgress style={styles.progress} />
              ) : (
                <div style={styles.number}>{this.state.users}</div>
              )}
              <div style={styles.subject}>users</div>
              <div style={styles.waiting}>waiting for validation</div>
            </Paper>
            <Paper
              style={styles.paper}
              onClick={() => {
                window.location =
                  '/#/user_companies?filter=%7B"status"%3A"wait_admin_validation"%7D';
              }}
            >
              {this.state.affiliationsLoading ? (
                <CircularProgress style={styles.progress} />
              ) : (
                <div style={styles.number}>{this.state.affiliations}</div>
              )}
              <div style={styles.subject}>affiliations</div>
              <div style={styles.waiting}>waiting for validation</div>
            </Paper>
          </div>
          {this.state.loadingCountries === false && (
            <>
              <Typography
                variant="display1"
                component="h2"
                gutterBottom
                style={styles.title}
              >
                Stats per year
              </Typography>
              <Select
                value={this.state.year}
                onChange={this.handleChangeYear}
                style={styles.select}
              >
                {years.map((value) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              <div style={styles.container}>
                {this.state.loadingYear ? (
                  <CircularProgress style={styles.progress} />
                ) : (
                  <Paper style={styles.paperTable}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Country</TableCell>
                          <TableCell align="right">Companies</TableCell>
                          <TableCell align="right">Beneficiaries</TableCell>
                          <TableCell align="right">Declarations</TableCell>
                          <TableCell align="right">Amount (EUR)</TableCell>
                          <TableCell align="right">Amount (GBP)</TableCell>
                          <TableCell align="right">Amount (CHF)</TableCell>
                          <TableCell align="right">Amount (USD)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rowsYear.map((row) => (
                          <TableRow key={row.country}>
                            <TableCell component="th" scope="row">
                              {row.country}
                            </TableCell>
                            <TableCell align="right">{row.companies}</TableCell>
                            <TableCell align="right">
                              {row.beneficiaries}
                            </TableCell>
                            <TableCell align="right">
                              {row.declarations}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_eur}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_gbp}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_chf}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_usd}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </div>
              <Typography
                variant="display1"
                component="h2"
                gutterBottom
                style={styles.title}
              >
                Stats per country
              </Typography>
              <Select
                value={this.state.country}
                onChange={this.handleChangeCountry}
                style={styles.select}
              >
                {Object.entries(this.countries).map((item) => {
                  return (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  );
                })}
              </Select>
              <div style={styles.container}>
                {this.state.loadingCountry ? (
                  <CircularProgress style={styles.progress} />
                ) : (
                  <Paper style={styles.paperTable}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell align="right">Companies</TableCell>
                          <TableCell align="right">Beneficiaries</TableCell>
                          <TableCell align="right">Declarations</TableCell>
                          <TableCell align="right">Amount (EUR)</TableCell>
                          <TableCell align="right">Amount (GBP)</TableCell>
                          <TableCell align="right">Amount (CHF)</TableCell>
                          <TableCell align="right">Amount (USD)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.rowsCountry.map((row) => (
                          <TableRow key={row.year}>
                            <TableCell component="th" scope="row">
                              {row.year}
                            </TableCell>
                            <TableCell align="right">{row.companies}</TableCell>
                            <TableCell align="right">
                              {row.beneficiaries}
                            </TableCell>
                            <TableCell align="right">
                              {row.declarations}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_eur}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_gbp}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_chf}
                            </TableCell>
                            <TableCell align="right">
                              {row.amount_usd}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </div>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default Dashboard;
