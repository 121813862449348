import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, showNotification } from "react-admin";
import axios from "axios";

const entrypoint = process.env.REACT_APP_ENTRYPOINT;

class BulkPublishButton extends Component {
  handleClick = () => {
    const { selectedIds, showNotification } = this.props;
    const token = localStorage.getItem("token");

    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${entrypoint}/publish_declarations`,
      data: {
        declarationIds: selectedIds.map((item) =>
          item.substr(item.lastIndexOf("/") + 1)
        ),
      },
    })
      .then((response) => {
        showNotification("Declarations published");
        window.location.reload(); // doing this to uncheck the checked items
      })
      .catch(function (error) {
        showNotification("Error: declarations not published", "warning");
      });
  };

  render() {
    return <Button label="Publish" onClick={this.handleClick} />;
  }
}

BulkPublishButton.propTypes = {
  showNotification: PropTypes.func,
};

export default connect(undefined, {
  showNotification,
})(BulkPublishButton);
