import React, { Fragment } from "react";
import {
  ListGuesser,
  EditGuesser,
  ShowGuesser,
  CreateGuesser,
} from "@api-platform/admin";
import {
  SelectInput,
  SelectField,
  TextField,
  ReferenceField,
  Pagination,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  TextInput,
} from "react-admin";
import BulkActivateButton from "./CustomActions/BulkActivateButton";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const StatusSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      {
        id: "wait_manager_validation",
        name: "Waiting for Company Administrator validation",
      },
    ]}
    {...props}
  />
);

const RoleSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "ROLE_MANAGER", name: "Company Administrator" },
      { id: "ROLE_ENCODER", name: "Only declarations manager" },
    ]}
    {...props}
  />
);

const StatusSelectField = (props) => (
  <SelectField
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      {
        id: "wait_manager_validation",
        name: "Waiting for Company Manager validation",
      },
    ]}
    {...props}
  />
);

const RoleSelectField = (props) => (
  <SelectField
    choices={[
      { id: "ROLE_MANAGER", name: "Company Administrator" },
      { id: "ROLE_ENCODER", name: "Only declarations manager" },
    ]}
    {...props}
  />
);

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const UserCompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Company" source="company.name" />
    <TextInput label="User" source="user.email" />
    <RoleSelectInput source={"role"} />
    <StatusSelectInput source={"status"} />
  </Filter>
);

const UserCompanyBulkActionButtons = (props) => (
  <Fragment>
    <BulkActivateButton label="Validate" {...props} />
  </Fragment>
);

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "company", "companies").then((companies) => {
    fetchRelatedRecords(records, "user", "users").then((users) => {
      const data = records.map((record) => ({
        user: users[record.user].email,
        company: companies[record.company].name,
        role: record.role,
        status: record.status,
      }));
      jsonExport(
        data,
        {
          headers: ["user", "company", "role", "status"],
        },
        (err, csv) => {
          downloadCSV(csv, "user_companies");
        }
      );
    });
  });
};

export const UserCompanyList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<UserCompanyFilter />}
    bulkActionButtons={<UserCompanyBulkActionButtons />}
    exporter={exporter}
  >
    <ReferenceField
      source="company"
      reference="companies"
      sortBy={"company.name"}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="User"
      source="user"
      reference="users"
      sortBy={"user.email"}
    >
      <TextField source="email" />
    </ReferenceField>
    <RoleSelectField source="role" />
    <StatusSelectField source="status" />
  </ListGuesser>
);

export const UserCompanyShow = (props) => (
  <ShowGuesser {...props}>
    <ReferenceField
      source="company"
      reference="companies"
      sortBy={"company.name"}
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="User"
      source="user"
      reference="users"
      sortBy={"user.email"}
    >
      <TextField source="email" />
    </ReferenceField>
    <RoleSelectField source="role" addLabel={true} />
    <StatusSelectField source="status" addLabel={true} />
  </ShowGuesser>
);

export const UserCompanyEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      label="Company"
      source="company"
      reference="companies"
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="User"
      source="user"
      reference="users"
      perPage={30}
      filterToQuery={(searchText) => ({ email: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <RoleSelectInput source={"role"} />
    <StatusSelectInput source={"status"} />
  </EditGuesser>
);

export const UserCompanyCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput
      label="Company"
      source="company"
      reference="companies"
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="User"
      source="user"
      reference="users"
      perPage={30}
      filterToQuery={(searchText) => ({ email: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <RoleSelectInput source={"role"} />
    <StatusSelectInput source={"status"} />
  </CreateGuesser>
);
