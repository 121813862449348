import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogin } from "react-admin";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";

const FORGOT_PASSWORD_URL = `${process.env.REACT_APP_CLIENT_URL}/forgot`;

class MyLoginPage extends Component {
  state = {
    username: "",
    password: "",
  };

  submit = (e) => {
    e.preventDefault();
    const credentials = {
      username: this.state.username,
      password: this.state.password,
    };

    this.props.userLogin(credentials);
  };

  setUsername = (value) => {
    this.setState({
      username: value,
    });
  };

  setPassword = (value) => {
    this.setState({
      password: value,
    });
  };

  render() {
    return (
      <MuiThemeProvider theme={this.props.theme}>
        <Card
          style={{
            width: "300px",
            margin: "6em auto 0",
          }}
        >
          <div
            style={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar>
              <LockIcon />
            </Avatar>
          </div>
          <form onSubmit={this.submit}>
            <div
              style={{
                padding: "0 1em 1em 1em",
              }}
            >
              <div
                style={{
                  marginTop: "1em",
                }}
              >
                <TextField
                  name="username"
                  type="username"
                  label="Username"
                  value={this.state.username}
                  onChange={(e) => this.setUsername(e.target.value)}
                  fullWidth
                />
              </div>
              <div
                style={{
                  marginTop: "1em",
                }}
              >
                <TextField
                  name="password"
                  type="password"
                  label="Password"
                  value={this.state.password}
                  onChange={(e) => this.setPassword(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <CardActions>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Sign in
              </Button>
            </CardActions>
          </form>
          <div
            style={{
              padding: "8px 12px",
            }}
          >
            <Button
              size="small"
              href={FORGOT_PASSWORD_URL}
              target="_blank"
              fullWidth
            >
              Forgot password?
            </Button>
          </div>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default connect(undefined, { userLogin })(MyLoginPage);
