import React from "react";
import {
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
  EditGuesser,
  InputGuesser,
  CreateGuesser,
} from "@api-platform/admin";
import {
  ReferenceField,
  TextField,
  EmailField,
  UrlField,
  DateField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Pagination,
  Filter,
  TextInput,
} from "react-admin";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Unique identifier" source="uniqueIdentifier" />
    <TextInput label="Country" source="country.name" />
    <TextInput label="Parent company" source="parent.name" />
    <ReferenceInput
      source="association"
      reference="associations"
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <InputGuesser source={"archived"} />
  </Filter>
);

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "parent", "companies").then((companies) => {
    fetchRelatedRecords(records, "country", "countries").then((countries) => {
      const data = records.map((record) => ({
        name: record.name,
        uniqueIdentifier: record.uniqueIdentifier,
        zipCode: record.zipCode,
        country: countries[record.country].name,
        parentCompany: record.parent ? companies[record.parent].name : "",
        contactEmail: record.contactEmail,
        contactFirstName: record.contactFirstName,
        contactLastName: record.contactLastName,
        contactPhone: record.contactPhone,
        contactUrl: record.contactUrl,
      }));
      jsonExport(
        data,
        {
          headers: [
            "name",
            "uniqueIdentifier",
            "zipCode",
            "country",
            "parent",
            "contactEmail",
            "contactFirstName",
            "contactLastName",
            "contactPhone",
            "contactUrl",
          ],
        },
        (err, csv) => {
          downloadCSV(csv, "companies");
        }
      );
    });
  });
};

export const CompanyList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<CompanyFilter />}
    exporter={exporter}
  >
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"uniqueIdentifier"} />
    <FieldGuesser source={"zipCode"} />
    <ReferenceField
      source="country"
      reference="countries"
      sortBy="country.name"
      allowEmpty
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Parent Company"
      source="parent"
      reference="companies"
      sortBy="parent.name"
      allowEmpty
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      source="association"
      reference="associations"
      sortBy="association.name"
      allowEmpty
    >
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export const CompanyShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"uniqueIdentifier"} addLabel={true} />
    <FieldGuesser source={"zipCode"} addLabel={true} />
    <ReferenceField source="country" reference="countries">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Parent Company"
      source="parent"
      reference="companies"
      allowEmpty
    >
      <TextField source="name" />
    </ReferenceField>

    <FieldGuesser source={"contactFirstName"} addLabel={true} />
    <FieldGuesser source={"contactLastName"} addLabel={true} />
    <EmailField source={"contactEmail"} addLabel={true} />
    <FieldGuesser source={"contactPhone"} addLabel={true} />
    <UrlField source={"contactUrl"} addLabel={true} />

    <ReferenceField source="association" reference="associations" allowEmpty>
      <TextField source="name" />
    </ReferenceField>

    <DateField source={"creationDate"} addLabel={true} />
    <FieldGuesser source={"archived"} addLabel={true} />
  </ShowGuesser>
);

export const CompanyEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"uniqueIdentifier"} />
    <InputGuesser source={"zipCode"} />
    <ReferenceInput
      source="country"
      reference="countries"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="Parent Company"
      source="parent"
      reference="companies"
      allowEmpty
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <InputGuesser source={"contactFirstName"} />
    <InputGuesser source={"contactLastName"} />
    <InputGuesser source={"contactEmail"} />
    <InputGuesser source={"contactPhone"} />
    <InputGuesser source={"contactUrl"} />

    <ReferenceInput
      source="association"
      reference="associations"
      allowEmpty
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <FieldGuesser source={"creationDate"} addLabel={true} />
    <InputGuesser source={"archived"} />
  </EditGuesser>
);

export const CompanyCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"uniqueIdentifier"} />
    <InputGuesser source={"zipCode"} />
    <ReferenceInput
      source="country"
      reference="countries"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="Parent Company"
      source="parent"
      reference="companies"
      allowEmpty
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <InputGuesser source={"contactFirstName"} />
    <InputGuesser source={"contactLastName"} />
    <InputGuesser source={"contactEmail"} />
    <InputGuesser source={"contactPhone"} />
    <InputGuesser source={"contactUrl"} />

    <ReferenceInput
      source="association"
      reference="associations"
      allowEmpty
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
);
