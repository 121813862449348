import React, { Fragment } from "react";
import {
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
  EditGuesser,
  InputGuesser,
  CreateGuesser,
} from "@api-platform/admin";
import {
  ChipField,
  NumberField,
  ReferenceField,
  TextField,
  SelectInput,
  SelectField,
  DateField,
  ReferenceInput,
  TextInput,
  DateInput,
  NumberInput,
  AutocompleteInput,
  Pagination,
  Filter,
  BulkDeleteButton,
} from "react-admin";
import BulkPublishButton from "./CustomActions/BulkPublishButton";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const DeclarationStatusSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "draft", name: "Draft" },
      { id: "published", name: "Published" },
      { id: "blocked", name: "Blocked" },
    ]}
    {...props}
  />
);

const DeclarationNatureSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "support_event", name: "Support to Educational Events" },
      { id: "other_grant", name: "Other Educational Grants" },
    ]}
    {...props}
  />
);

const DeclarationNatureSelectField = (props) => (
  <SelectField
    choices={[
      { id: "support_event", name: "Support to Educational Events" },
      { id: "other_grant", name: "Other Educational Grants" },
    ]}
    {...props}
  />
);

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 500]} {...props} />
);

const DeclarationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Company" source="company.name" />
    <TextInput label="Beneficiary" source="beneficiary.name" />
    <TextInput label="Year" source="year" />
    <DeclarationNatureSelectInput source={"nature"} />
    <DeclarationStatusSelectInput source={"status"} />
    <TextInput label="Currency" source="currency.name" />
  </Filter>
);

const DeclarationBulkActionButtons = (props) => (
  <Fragment>
    <BulkPublishButton label="Publish" {...props} />
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </Fragment>
);

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "beneficiary", "beneficiaries").then(
    (beneficiaries) => {
      fetchRelatedRecords(records, "company", "companies").then((companies) => {
        fetchRelatedRecords(records, "currency", "currencies").then(
          (currencies) => {
            const data = records.map((record) => ({
              company: companies[record.company].name,
              year: record.year,
              amount: record.amount,
              currency: currencies[record.currency].code,
              beneficiary: beneficiaries[record.beneficiary].name,
              nature: record.nature,
              description: record.description,
            }));
            jsonExport(
              data,
              {
                headers: [
                  "company",
                  "year",
                  "amount",
                  "currency",
                  "beneficiary",
                  "nature",
                  "description",
                ],
              },
              (err, csv) => {
                downloadCSV(csv, "declarations");
              }
            );
          }
        );
      });
    }
  );
};

export const DeclarationList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<DeclarationFilter />}
    bulkActionButtons={<DeclarationBulkActionButtons />}
    exporter={exporter}
  >
    <ReferenceField
      source="company"
      reference="companies"
      sortBy={"company.name"}
    >
      <TextField source="name" />
    </ReferenceField>
    <TextField source={"year"} />
    <NumberField source={"amount"} />
    <ReferenceField
      source="currency"
      reference="currencies"
      sortBy={"currency.code"}
    >
      <TextField source="code" />
    </ReferenceField>
    <ReferenceField
      source="beneficiary"
      reference="beneficiaries"
      sortBy={"beneficiary.name"}
    >
      <TextField source="name" />
    </ReferenceField>
    <DeclarationNatureSelectField source={"nature"} />
    <ChipField source={"status"} />
  </ListGuesser>
);

export const DeclarationShow = (props) => (
  <ShowGuesser {...props}>
    <ReferenceField source="company" reference="companies">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="User encoder" source="user" reference="users">
      <TextField source="email" />
    </ReferenceField>
    <TextField source={"year"} addLabel={true} />
    <NumberField source={"amount"} addLabel={true} />
    <ReferenceField source="currency" reference="currencies">
      <TextField source="code" />
    </ReferenceField>
    <ReferenceField source="beneficiary" reference="beneficiaries">
      <TextField source="name" />
    </ReferenceField>
    <DeclarationNatureSelectField source={"nature"} addLabel={true} />
    <TextField source={"description"} addLabel={true} />
    <ChipField source={"status"} addLabel={true} />
    <DateField source={"declarationDate"} addLabel={true} />
    <FieldGuesser source={"archived"} addLabel={true} />
  </ShowGuesser>
);

export const DeclarationEdit = (props) => (
  <EditGuesser {...props}>
    <ReferenceInput
      source="company"
      reference="companies"
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      label="User encoder"
      source="user"
      reference="users"
      perPage={30}
      filterToQuery={(searchText) => ({ email: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>

    <TextInput source={"year"} />
    <NumberInput source={"amount"} />

    <ReferenceInput
      source="currency"
      reference="currencies"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="beneficiary"
      reference="beneficiaries"
      perPage={5}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <DeclarationNatureSelectInput source={"nature"} />
    <TextInput multiline source={"description"} />
    <DeclarationStatusSelectInput source={"status"} />
    <DateInput source={"declarationDate"} />
    <InputGuesser source={"archived"} />
  </EditGuesser>
);

export const DeclarationCreate = (props) => (
  <CreateGuesser {...props}>
    <ReferenceInput
      source="company"
      reference="companies"
      perPage={30}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <NumberInput source={"year"} />
    <NumberInput source={"amount"} />

    <ReferenceInput
      source="currency"
      reference="currencies"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="beneficiary"
      reference="beneficiaries"
      perPage={5}
      filterToQuery={(searchText) => ({ name: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <DeclarationNatureSelectInput source={"nature"} />
    <TextInput multiline source={"description"} />
    <DeclarationStatusSelectInput source={"status"} />
  </CreateGuesser>
);
