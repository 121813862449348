import React from "react";
import {
  ListGuesser,
  FieldGuesser,
  EditGuesser,
  InputGuesser,
  CreateGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import { ChipField, SelectInput, SelectField, Pagination } from "react-admin";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const StatusSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
    ]}
    {...props}
  />
);

const StatusSelectField = (props) => (
  <SelectField
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
    ]}
    {...props}
  />
);

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const exporter = (records) => {
  const data = records.map((record) => ({
    name: record.name,
    code: record.code,
    status: record.status,
  }));
  jsonExport(
    data,
    {
      headers: ["name", "code", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "currencies");
    }
  );
};

export const CurrencyList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    exporter={exporter}
  >
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"code"} />
    <ChipField source={"status"} />
  </ListGuesser>
);

export const CurrencyShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"code"} addLabel={true} />
    <StatusSelectField source={"status"} addLabel={true} />
  </ShowGuesser>
);

export const CurrencyEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"code"} />
    <StatusSelectInput source={"status"} />
  </EditGuesser>
);

export const CurrencyCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"code"} />
    <StatusSelectInput source={"status"} />
  </CreateGuesser>
);
