import React from "react";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import { HydraAdmin } from "@api-platform/admin";
import {
  dataProvider as baseDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import authProvider from "./authProvider";
import { Route, Redirect } from "react-router-dom";
import { Resource } from "react-admin";

import UserIcon from "@material-ui/icons/People";
import AdminIcon from "@material-ui/icons/PeopleOutline";
import CompanyIcon from "@material-ui/icons/Domain";
import CountryIcon from "@material-ui/icons/Flag";
import DeclarationIcon from "@material-ui/icons/Assignment";
import BeneficiaryIcon from "@material-ui/icons/Mood";
import CurrencyIcon from "@material-ui/icons/AttachMoney";
import WorkIcon from "@material-ui/icons/Work";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import Dashboard from "./dashboard";
import MyLoginPage from "./login";
import MyLayout from "./layout";

import {
  BeneficiaryList,
  BeneficiaryShow,
  BeneficiaryEdit,
  BeneficiaryCreate,
} from "./beneficiaries";
import {
  CompanyList,
  CompanyShow,
  CompanyEdit,
  CompanyCreate,
} from "./companies";
import {
  CountryList,
  CountryShow,
  CountryEdit,
  CountryCreate,
} from "./countries";
import {
  CurrencyList,
  CurrencyShow,
  CurrencyEdit,
  CurrencyCreate,
} from "./currencies";
import {
  DeclarationList,
  DeclarationShow,
  DeclarationEdit,
  DeclarationCreate,
} from "./declarations";
import { UserList, UserShow, UserEdit } from "./users";
import {
  UserCompanyList,
  UserCompanyShow,
  UserCompanyCreate,
  UserCompanyEdit,
} from "./userCompanies";
import {
  UserAdminList,
  UserAdminShow,
  UserAdminEdit,
  UserAdminCreate,
} from "./adminUsers";
import ListGuesser from "@api-platform/admin/lib/ListGuesser";
import ShowGuesser from "@api-platform/admin/lib/ShowGuesser";
import EditGuesser from "@api-platform/admin/lib/EditGuesser";
import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";

const entrypoint = process.env.REACT_APP_ENTRYPOINT; // Change this by your own entrypoint if you're not using API Platform distribution
const fetchHeaders = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });

const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => ({ api }),
    (result) => {
      const { api, status } = result;

      if (status === 401) {
        return Promise.resolve({
          api,
          status,
          customRoutes: [
            <Route path="/" render={() => <Redirect to="/login" />} />,
          ],
        });
      }

      return Promise.reject(result);
    }
  );

const dataProvider = baseDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);

export default () => (
  <HydraAdmin
    apiDocumentationParser={apiDocumentationParser}
    dataProvider={dataProvider}
    authProvider={authProvider}
    entrypoint={entrypoint}
    loginPage={MyLoginPage}
    dashboard={Dashboard}
    appLayout={MyLayout}
  >
    {(permissions) => [
      <Resource
        name="associations"
        icon={SwapHorizIcon}
        list={ListGuesser}
        show={ShowGuesser}
        edit={EditGuesser}
        create={CreateGuesser}
      />,
      <Resource
        name="beneficiaries"
        icon={BeneficiaryIcon}
        list={BeneficiaryList}
        show={BeneficiaryShow}
        edit={BeneficiaryEdit}
        create={BeneficiaryCreate}
      />,
      <Resource
        name="companies"
        icon={CompanyIcon}
        list={CompanyList}
        show={CompanyShow}
        edit={CompanyEdit}
        create={CompanyCreate}
      />,
      <Resource
        name="countries"
        icon={CountryIcon}
        list={CountryList}
        edit={CountryEdit}
        create={CountryCreate}
        show={CountryShow}
      />,
      <Resource
        name="currencies"
        icon={CurrencyIcon}
        list={CurrencyList}
        edit={CurrencyEdit}
        create={CurrencyCreate}
        show={CurrencyShow}
      />,
      <Resource
        name="declarations"
        icon={DeclarationIcon}
        list={DeclarationList}
        show={DeclarationShow}
        edit={DeclarationEdit}
        create={DeclarationCreate}
      />,
      <Resource
        name="users"
        options={{ label: "Users" }}
        icon={UserIcon}
        list={UserList}
        edit={UserEdit}
        show={UserShow}
      />,
      <Resource
        name="user_companies"
        icon={WorkIcon}
        list={UserCompanyList}
        show={UserCompanyShow}
        edit={UserCompanyEdit}
        create={UserCompanyCreate}
      />,

      permissions === "super_admin" ? (
        <Resource
          name="admin_users"
          icon={AdminIcon}
          options={{ label: "Admin Users" }}
          list={UserAdminList}
          edit={UserAdminEdit}
          show={UserAdminShow}
          create={UserAdminCreate}
        />
      ) : null,
    ]}
  </HydraAdmin>
);
