import React, { Fragment } from "react";
import {
  ListGuesser,
  FieldGuesser,
  EditGuesser,
  InputGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import {
  SelectInput,
  SelectField,
  TextField,
  ListActions,
  Pagination,
  Filter,
  TextInput,
} from "react-admin";
import BulkActivateButton from "./CustomActions/BulkActivateButton";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const StatusSelectInput = (props) => (
  <SelectInput
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      { id: "wait_email_validation", name: "Waiting for email validation" },
    ]}
    {...props}
  />
);

const StatusSelectField = (props) => (
  <SelectField
    choices={[
      { id: "active", name: "Active" },
      { id: "inactive", name: "Inactive" },
      { id: "wait_admin_validation", name: "Waiting for Admin validation" },
      { id: "wait_email_validation", name: "Waiting for email validation" },
    ]}
    {...props}
  />
);

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="Last Name" source="lastName" />
    <TextInput label="Username" source="username" />
    <StatusSelectInput source={"status"} />
  </Filter>
);

const UserBulkActionButtons = (props) => (
  <Fragment>
    <BulkActivateButton label="Validate" {...props} />
  </Fragment>
);

const exporter = (records) => {
  const data = records.map((record) => ({
    email: record.email,
    username: record.username,
    firstName: record.firstName,
    lastName: record.lastName,
    phone: record.phone,
    status: record.status,
  }));
  jsonExport(
    data,
    {
      headers: [
        "email",
        "username",
        "firstName",
        "lastName",
        "phone",
        "status",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "users");
    }
  );
};

export const UserList = (props) => (
  <ListGuesser
    {...props}
    actions={<ListActions hasCreate={false} />}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<UserFilter />}
    filterDefaultValues={{ type: "user" }}
    bulkActionButtons={<UserBulkActionButtons />}
    exporter={exporter}
  >
    <FieldGuesser source={"firstName"} />
    <FieldGuesser source={"lastName"} />
    <FieldGuesser source={"email"} />
    <StatusSelectField source="status" />
    <FieldGuesser source={"legacyRole"} />
  </ListGuesser>
);

export const UserShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="firstName" addLabel={true} />
    <FieldGuesser source="lastName" addLabel={true} />
    <FieldGuesser source="email" addLabel={true} />
    <FieldGuesser source="username" addLabel={true} />
    <FieldGuesser source="phone" addLabel={true} />
    <StatusSelectField source="status" addLabel={true} />
    <FieldGuesser source="legacyRole" addLabel={true} />
    <TextField source="roles" addLabel={true} />
  </ShowGuesser>
);

export const UserEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source="firstName" />
    <InputGuesser source="lastName" />
    <InputGuesser source="email" />
    <InputGuesser source="username" />
    <InputGuesser source="phone" />
    <StatusSelectInput source="status" />
  </EditGuesser>
);
