import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import jwt from 'jwt-decode';

// Change this to be your own authentication token URI.
const authenticationTokenUri = `${process.env.REACT_APP_ADMIN_AUTHENTICATION}`;

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      const { username, password } = params;
      const request = new Request(authenticationTokenUri, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);

          return response.json();
        })
        .then(({ token }) => {
          const user = jwt(token);
          if (user.roles.includes('ROLE_ADMIN')) {
              localStorage.setItem('token', token); // The JWT token is stored in the browser's local storage
              localStorage.setItem('role', 'admin');
              if (user.roles.includes('ROLE_SUPER_ADMIN')) {
                localStorage.setItem('role', 'super_admin');
              }
              window.location.replace('/');
          }

        });

    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      break;

    case AUTH_ERROR:
      if (401 === params.response.status || 403 === params.response.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');

        return Promise.reject();
      }
      break;

    case AUTH_CHECK:
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();

    case AUTH_GET_PERMISSIONS:
      const role = localStorage.getItem('role');
      return role ? Promise.resolve(role) : Promise.reject();

      default:
          return Promise.resolve();
  }
}