import React from "react";
import {
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
  EditGuesser,
  InputGuesser,
  CreateGuesser,
} from "@api-platform/admin";
import {
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Pagination,
  Filter,
  TextInput,
} from "react-admin";

import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const BeneficiaryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Unique identifier" source="uniqueIdentifier" />
    <TextInput label="City" source="city" />
    <TextInput label="Country" source="country.name" />
  </Filter>
);

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, "country", "countries").then((countries) => {
    const data = records.map((record) => ({
      name: record.name,
      uniqueIdentifier: record.uniqueIdentifier,
      zipCode: record.zipCode,
      city: record.city,
      country: countries[record.country].name,
    }));
    jsonExport(
      data,
      {
        headers: ["name", "uniqueIdentifier", "zipCode", "city", "country"],
      },
      (err, csv) => {
        downloadCSV(csv, "beneficiaries");
      }
    );
  });
};

export const BeneficiaryList = (props) => (
  <ListGuesser
    {...props}
    perPage={25}
    pagination={<CustomPagination />}
    filters={<BeneficiaryFilter />}
    exporter={exporter}
  >
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"uniqueIdentifier"} />
    <FieldGuesser source={"zipCode"} />
    <FieldGuesser source={"city"} />
    <ReferenceField
      source="country"
      reference="countries"
      sortBy="country.name"
    >
      <TextField source="name" />
    </ReferenceField>
  </ListGuesser>
);

export const BeneficiaryShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"uniqueIdentifier"} addLabel={true} />
    <FieldGuesser source={"address"} addLabel={true} />
    <FieldGuesser source={"zipCode"} addLabel={true} />
    <FieldGuesser source={"city"} addLabel={true} />
    <ReferenceField source="country" reference="countries">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField source="user" reference="users">
      <TextField source="email" />
    </ReferenceField>
  </ShowGuesser>
);

export const BeneficiaryEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"uniqueIdentifier"} />
    <InputGuesser source={"address"} />
    <InputGuesser source={"zipCode"} />
    <InputGuesser source={"city"} />
    <ReferenceInput
      source="country"
      reference="countries"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="user"
      reference="users"
      perPage={30}
      filterToQuery={(searchText) => ({ email: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </EditGuesser>
);

export const BeneficiaryCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"uniqueIdentifier"} />
    <InputGuesser source={"address"} />
    <InputGuesser source={"zipCode"} />
    <InputGuesser source={"city"} />
    <ReferenceInput
      source="country"
      reference="countries"
      filter={{ status: "active" }}
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput
      source="user"
      reference="users"
      perPage={30}
      filterToQuery={(searchText) => ({ email: searchText })}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 1;
      }}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </CreateGuesser>
);
